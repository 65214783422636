<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{
      roleId:localStorage.getItem("roleId"),
      companyType:localStorage.getItem("companyType"),
    }
  },
  created(){

  },
  mounted() {
    if (this.roleId == 0) {
      document.title = localStorage.getItem("companyName");
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href =localStorage.getItem("companyLogo");
      document.getElementsByTagName('head')[0].appendChild(link);
    }else if (this.companyType == '444') {
      document.title = localStorage.getItem("associationName")
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = localStorage.getItem("logo");
      document.getElementsByTagName('head')[0].appendChild(link);
    }else{
      document.title = localStorage.getItem("companyName")?localStorage.getItem("companyName"):''
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = localStorage.getItem("companyLogo");
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if(!this.roleId && !this.companyType){
      document.title = '社团智慧管理服务系统'
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = '/favicon.ico';
      document.head.appendChild(link);
    }

  },
  methods:{
  }
};
</script>

<style>
  .ql-editor {
    white-space: normal !important;
  }

  .ql-container {
    white-space: pre-wrap !important;
  }

 .tbodyTitle .el-upload--picture-card{
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .tbodyTitle .el-icon-plus{
    font-size: 18px;
  }

  .tbodyTitle .el-upload-list--picture-card .el-upload-list__item {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .tbodyTitle .el-upload-list--picture-card{
    /*display: none;*/
  }

</style>
