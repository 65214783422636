import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'history',
    routes: [{
            path: "/",
            redirect: "/dashboard"
        },
        {
            path: "/",
            component: () =>
                import ("../components/Home.vue"),
            meta: { title: "home" },
            children: [{
                    path: "dashboard",
                    component: () =>
                        import ("../pages/dashboard"),
                    meta: { title: "工作台", keepAlive: false }
                },
                /* {
                            path: 'customer',
                            component: () => import('../pages/customer'),
                            meta: { title: '客户管理' }
                        }, */
                {
                    path: "customerInput",
                    component: () =>
                        import ("../pages/customer/customerInput.vue"),
                    meta: { title: "客户录入", keepAlive: false }
                },
                {
                    path: "customerManagement",
                    component: () =>
                        import ("../pages/customer/customerManagement.vue"),
                    meta: { title: "客户管理", keepAlive: false }
                },
                {
                    path: "customerDetall",
                    component: () =>
                        import ("../pages/customer/customerDetall.vue"),
                    meta: { title: "客户详情", keepAlive: false }
                },
                {
                    path: "totalCustomer",
                    component: () =>
                        import ("../pages/customer/totalCustomer.vue"),
                    meta: { title: "总客户池", keepAlive: false }
                },
                {
                    path: "visitCustomers",
                    component: () =>
                        import ("../pages/customer/visitCustomers.vue"),
                    meta: { title: "访问客户", keepAlive: false }
                },
                /* 	{
                            path: 'form',
                            component: () => import('../pages/form'),
                            meta: { title: '企业管理' }
                        }, */
                {
                    path: 'corporateInformation',
                    component: () =>
                        import ('../pages/businessManagement/corporateInformation'),
                    meta: { title: '企业信息', keepAlive: false }
                },
                {
                    path: 'enterpriseMember',
                    component: () =>
                        import ('../pages/businessManagement/enterpriseMember'),
                    meta: { title: '企业成员', keepAlive: false }
                },
                {
                    path: 'memberDetall',
                    component: () =>
                        import ('../pages/businessManagement/memberDetall'),
                    meta: { title: '成员详情', keepAlive: false }
                },
                {
                    path: 'businessCard',
                    component: () =>
                        import ('../pages/businessManagement/businessCard/card'),
                    meta: { title: '编辑名片', keepAlive: false }
                },
                {
                    path: 'commonFun',
                    component: () =>
                        import ('../pages/businessManagement/commonFun/commonfun'),
                    meta: { title: '常用功能', keepAlive: false }
                },
                {
                    path: 'mobileWeb',
                    component: () =>
                        import ('../pages/mobileWeb/mobileweb'),
                    meta: { title: '移动官网管理', keepAlive: false }
                },
                {
                    path: 'sectorSetup',
                    component: () =>
                        import ('../pages/businessManagement/sectorSetup/sectorSetup'),
                    meta: { title: '部门设置', keepAlive: false }
                },
                {
                    path: 'AdministratorList',
                    component: () =>
                        import ('../pages/businessManagement/AdministratorList/AdministratorList'),
                    meta: { title: '管理员列表', keepAlive: false }
                },
                {
                    path: 'AdministratorDetail',
                    component: () =>
                        import ('../pages/businessManagement/AdministratorList/AdministratorDetail'),
                    meta: { title: '管理员设置', keepAlive: false }
                },
                {
                    path: 'launchProducts',
                    component: () =>
                        import ('../pages/merchandiseManagement/appointment/management'),
                    meta: { title: '发布产品', keepAlive: false }
                },
                {
                    path: 'launchProductsSH',
                    component: () =>
                        import ('../pages/merchandiseManagement/appointmentSH/management'),
                    meta: { title: '发布产品', keepAlive: false }
                },
                {
                    path: 'advancedForm',
                    component: () =>
                        import ('../pages/businessManagement/advancedForm'),
                    meta: { title: '高级表单', keepAlive: false }
                },
                {
                    path: 'publishAdvancedForm',
                    name: 'publishAdvancedForm',
                    component: () =>
                        import ('../pages/businessManagement/publishAdvancedForm'),
                    meta: { title: '新增表单', keepAlive: false }
                },
                {
                    path: 'formDetall',
                    name: 'formDetall',
                    component: () =>
                        import ('../pages/businessManagement/formDetall'),
                    meta: { title: '表单详情', keepAlive: false }
                },
                // {
                // 	path: 'websiteSettings',
                // 	component: () => import('../pages/businessManagement/websiteSettings'),
                // 	meta: { title: '官网设置' }
                // },
                {
                    path: 'advertisingManagement',
                    component: () =>
                        import ('../pages/businessManagement/advertisingManagement'),
                    meta: { title: '广告图管理', keepAlive: false }
                },
                {
                    path: 'setBanner',
                    component: () =>
                        import ('../pages/businessManagement/setBanner'),
                    meta: { title: '广告图设置', keepAlive: false }
                },
                {
                    path: 'orderManagement',
                    component: () =>
                        import ('../pages/orderManagement'),
                    meta: { title: '订单管理', keepAlive: false }
                },
                {
                    path: 'commissionManagement',
                    component: () =>
                        import ('../pages/financialManagement/commissionManagement'),
                    meta: { title: '佣金管理', keepAlive: false }
                },
                {
                    path: 'agentManage',
                    component: () =>
                        import ('../pages/agentManage/agentManage'),
                    meta: { title: '代理企业', keepAlive: false }
                },
                {
                    path: 'companyDetail',
                    component: () =>
                        import ('../pages/agentManage/companyDetail'),
                    meta: { title: '代理企业详情', keepAlive: false }
                },
                {
                    path: 'personalCommissionDetall',
                    component: () =>
                        import ('../pages/financialManagement/personalCommissionDetall'),
                    meta: { title: '个人佣金详情', keepAlive: false }
                },
                {
                    path: 'moneyManagement',
                    component: () =>
                        import ('../pages/financialManagement/moneyManagement'),
                    meta: { title: '资金管理', keepAlive: false }
                },
                {
                    path: 'management',
                    component: () =>
                        import ('../pages/merchandiseManagement/management'),
                    meta: { title: '商品管理', keepAlive: false }
                },
                // 结算单 --- 企业
                {
                    path: 'statementsQY',
                    component: () =>
                        import ('../pages/statementsQY/index'),
                    meta: { title: '结算单', keepAlive: false }
                },
                // 结算单 --- 商会
                {
                    path: 'wait_statements',
                    component: () =>
                        import ('../pages/settlement/wait_statements'),
                    meta: { title: '结算单(待结算)', keepAlive: false }
                },
                {
                    path: 'alery_statements',
                    component: () =>
                        import ('../pages/settlement/alery_statements'),
                    meta: { title: '结算单(已结算)', keepAlive: false }
                },
                {
                    path: 'settlementAll',
                    component: () =>
                        import ('../pages/settlement/settlementAll'),
                    meta: { title: '批量结算', keepAlive: false }
                },
                {
                    path: 'credentials',
                    component: () =>
                        import ('../pages/settlement/credentials'),
                    meta: { title: '凭证', keepAlive: false }
                },
                {
                    path: 'appointmentManagement',
                    component: () =>
                        import ('../pages/merchandiseManagement/appointmentManagement'),
                    meta: { title: '预约管理', keepAlive: false }
                },
                {
                    path: 'appointmentManagementSH',
                    component: () =>
                        import ('../pages/merchandiseManagement/appointmentManagementSH'),
                    meta: { title: '预约管理', keepAlive: false }
                },
                {
                    path: 'freightManagement',
                    component: () =>
                        import ('../pages/merchandiseManagement/freightManagement'),
                    meta: { title: '运费管理', keepAlive: false }
                },
                {
                    path: 'distributionStatistics',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/distributionStatistics'),
                    meta: { title: '分销统计', keepAlive: false }
                },
                {
                    path: 'personnelApplication',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/personnelApplication'),
                    meta: { title: '人员申请', keepAlive: false }
                },
                {
                    path: 'distributionLevel',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/distributionLevel'),
                    meta: { title: '分销等级', keepAlive: false }
                },
                {
                    path: 'distributionOrder',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/distributionOrder'),
                    meta: { title: '分销订单', keepAlive: false }
                },
                {
                    path: 'accountTransfer',
                    component: () =>
                        import ('../pages/marketingActivities/distribution/accountTransfer'),
                    meta: { title: '到账流水', keepAlive: false }
                },
                {
                    path: 'productList',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/productList'),
                    meta: { title: '拼团商品列表', keepAlive: false }
                },
                {
                    path: 'listParticipants',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/listParticipants'),
                    meta: { title: '参团列表', keepAlive: false }
                },
                {
                    path: 'assembleOrdersInfo',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/assembleOrdersInfo'),
                    meta: { title: '订单详情', keepAlive: false }
                },
                {
                    path: 'addAssemble',
                    component: () =>
                        import ('../pages/marketingActivities/joinGroup/components/addAssemble'),
                    meta: { title: '拼团配置', keepAlive: false }
                },
                {
                    path: 'spikeList',
                    component: () =>
                        import ('../pages/marketingActivities/spike'),
                    meta: { title: '秒杀商品列表', keepAlive: false }
                },
                {
                    path: 'addSpike',
                    component: () =>
                        import ('../pages/marketingActivities/spike/components/addSpike'),
                    meta: { title: '秒杀设置', keepAlive: false }
                },
                {
                    path: 'couponList',
                    component: () =>
                        import ('../pages/marketingActivities/couponManagement/couponList'),
                    meta: { title: '优惠券列表', keepAlive: false }
                },
                {
                    path: 'detailCoupon',
                    component: () =>
                        import ('../pages/marketingActivities/couponManagement/detailCoupon'),
                    meta: { title: '领取详情', keepAlive: false }
                },

                {
                    path: 'customerArticles',
                    component: () =>
                        import ('../pages/articleManagement/toPromote/customerArticles'),
                    meta: { title: '获客文章', keepAlive: false }
                },
                {
                    path: 'isDrafts',
                    component: () =>
                        import ('../pages/articleManagement/toPromote/isDrafts'),
                    meta: { title: '获客文章草稿箱', keepAlive: false }
                },
                {
                    path: 'employeeData',
                    component: () =>
                        import ('../pages/articleManagement/toPromote/employeeData'),
                    meta: { title: '员工数据', keepAlive: false }
                },
                {
                    path: 'staffDataDetail',
                    component: () =>
                        import ('../pages/articleManagement/components/staffDataDetail'),
                    meta: { title: '员工数据', keepAlive: false }
                },
                {
                    path: 'visitorData',
                    component: () =>
                        import ('../pages/articleManagement/toPromote/visitorData'),
                    meta: { title: '访客数据', keepAlive: false }
                },
                {
                    path: 'visitorData',
                    component: () =>
                        import ('../pages/articleManagement/toPromote/visitorData'),
                    meta: { title: '访客数据', keepAlive: false }
                },
                {
                    path: 'enterpriseDT',
                    component: () =>
                        import ('../pages/articleManagement/enterpriseDT'),
                    meta: { title: '企业动态', keepAlive: false }
                },
                {
                    path: 'videoManage',
                    component: () =>
                        import ('../pages/videoManage'),
                    meta: { title: '短视频管理', keepAlive: false }
                },
                {
                    path: 'videoManageZZ',
                    component: () =>
                        import ('../pages/videoManageZZ'),
                    meta: { title: '短视频管理', keepAlive: false }
                },
                {
                    path: 'myNeeds',
                    component: () =>
                        import ('../pages/addedServices/myNeeds'),
                    meta: { title: '我的需求', keepAlive: false }
                },
                {
                    path: 'demandCheck',
                    component: () =>
                        import ('../pages/addedServices/demandCheck'),
                    meta: { title: '需求审核', keepAlive: false }
                },
                {
                    path: 'demandContactsCheck',
                    component: () =>
                        import ('../pages/addedServices/demandContactsCheck'),
                    meta: { title: '人脉集市审核', keepAlive: false }
                },
                {
                    path: 'demandProtuctsCheck',
                    component: () =>
                        import ('../pages/addedServices/demandProtuctsCheck'),
                    meta: { title: '商品审核', keepAlive: false }
                },
                // 商品审核 --- 商会
                {
                    path: 'demandProtuctsCheckSH',
                    component: () =>
                        import ('../pages/addedServices/demandProtuctsCheckSH'),
                    meta: { title: '商品审核', keepAlive: false }
                },
                {
                    path: 'demandPublishSetting',
                    component: () =>
                        import ('../pages/addedServices/demandPublishSetting'),
                    meta: { title: '发布设置', keepAlive: false }
                },
                {
                    path: 'demandReport',
                    component: () =>
                        import ('../pages/addedServices/demandReport'),
                    meta: { title: '举报处理', keepAlive: false }
                },
                {
                    path: 'demandCustomize',
                    component: () =>
                        import ('../pages/addedServices/demandCustomize'),
                    meta: { title: '自定义设置', keepAlive: false }
                },
                {
                    path: 'demandPublishForm',
                    component: () =>
                        import ('../pages/addedServices/components/demandPublishForm'),
                    meta: { title: '发布需求', keepAlive: false }
                },
                {
                    path: 'posterAtlas',
                    component: () =>
                        import ('../pages/addedServices/sharingPromotion/posterAtlas'),
                    meta: { title: '海报图集', keepAlive: false }
                },
                {
                    path: 'posterAtlasDetall',
                    component: () =>
                        import ('../pages/addedServices/sharingPromotion/posterAtlasDetall'),
                    meta: { title: '海报图集详情', keepAlive: false }
                },
                {
                    path: 'clockData',
                    component: () =>
                        import ('../pages/addedServices/clockData'),
                    meta: { title: '打卡数据', keepAlive: false }
                },
                {
                    path: 'clockDataDetall',
                    component: () =>
                        import ('../pages/addedServices/clockData/clockDataDetall'),
                    meta: { title: '打卡详情', keepAlive: false }
                },
                {
                    path: 'appletConfig',
                    component: () =>
                        import ('../pages/miniProgram/appletConfig'),
                    meta: { title: '小程序配置', keepAlive: false }
                },
                //服务商
                {
                    path: 'toPayData',
                    component: () =>
                        import ('../pages/serviceProviders/toPayData'),
                    meta: { title: '服务商支付', keepAlive: false }
                },
                // {
                // 	path:'basicSetting',
                // 	component: () => import('../pages/miniProgram/basicSetting'),
                // 	meta: {
                // 		title: '基础设置',
                // 		keepAlive: false
                //  	}
                // },
                // {
                // 	path:'miniProgram',
                // 	component: () => import('../pages/miniProgram'),
                // 	meta: { title: '小程序配置' }
                // },
                {
                    path: 'mallSetting',
                    component: () =>
                        import ('../pages/miniProgram/mallSetting'),
                    meta: { title: '商城设置', keepAlive: true }
                },
                {
                    path: 'mallSettingSH',
                    component: () =>
                        import ('../pages/miniProgram/mallSettingSH'),
                    meta: { title: '商城设置', keepAlive: true }
                },
                // {
                // 	path:'mallTemplate',
                // 	component: () => import('../pages/miniProgram/mallTemplate'),
                // 	meta: { title: '商城模板设置',keepAlive: false }
                // },
                {
                    path: 'templateA',
                    component: () =>
                        import ('../pages/miniProgram/templateA'),
                    meta: { title: '综合电商', keepAlive: true }
                },
                {
                    path: 'templateB',
                    component: () =>
                        import ('../pages/miniProgram/templateB'),
                    meta: { title: '节日优惠', keepAlive: true }
                },
                {
                    path: 'templateC',
                    component: () =>
                        import ('../pages/miniProgram/templateC'),
                    meta: { title: '日用百货', keepAlive: true }
                },
                {
                    path: 'templateD',
                    component: () =>
                        import ('../pages/miniProgram/templateD'),
                    meta: { title: '数码家电', keepAlive: true }
                },
                {
                    path: 'goodsDetails',
                    component: () =>
                        import ('../pages/merchandiseManagement/goodsDetails'),
                    meta: { title: '商品详情', keepAlive: false }
                },
                //企业的商品发布
                {
                    path: 'redactOrAdd',
                    component: () =>
                        import ('../pages/merchandiseManagement/redactOrAdd'),
                    meta: { title: '商品发布', keepAlive: false }
                },

                //组织信息
                // {
                //     path: 'shInformation',
                //     component: () => import('../pages/ChamberCommerce/shInformation'),
                //     meta: {title: '组织信息', keepAlive: false}
                // },
                //组织设置---会员级别
                {
                    path: 'membersLevel',
                    component: () =>
                        import ('../pages/members/membersLevel'),
                    meta: { title: '会员级别', keepAlive: false }
                },
                //组织设置---会费设置
                {
                    path: 'membersMoney',
                    component: () =>
                        import ('../pages/members/membersMoney'),
                    meta: { title: '会费设置', keepAlive: false }
                },
                //组织设置---入会管理
                {
                    path: 'shInformation',
                    component: () =>
                        import ('../pages/members/membershipManagement'),
                    meta: { title: '入会管理', keepAlive: false }
                },
                // //组织设置---入会协议规则
                // {
                //     path: 'memberRules',
                //     component: () => import('../pages/members/components/memberRules'),
                //     meta: {title: '入会协议规则', keepAlive: false}
                // },
                // //组织设置---入会协议规则
                // {
                //     path: 'memberMoneyRules',
                //     component: () => import('../pages/members/components/memberMoneyRules'),
                //     meta: {title: '会费协议规则', keepAlive: false}
                // },
                //商城管理
                {
                    path: 'managementSh',
                    component: () =>
                        import ('../pages/membersManagement/management'),
                    meta: { title: '商品管理', keepAlive: false }
                },
                //商会商品发布 ---- 组织
                {
                    path: 'redactOrAddSH',
                    component: () =>
                        import ('../pages/membersManagement/redactOrAddSH'),
                    meta: { title: '商品发布', keepAlive: false }
                },
                //商会订单管理
                {
                    path: 'orderManagementSh',
                    component: () =>
                        import ('../pages/orderManagementSh'),
                    meta: { title: '订单管理', keepAlive: false }
                },
                //组织官网-基本设置
                {
                    path: 'basicSetup',
                    component: () =>
                        import ('../pages/website/basicSetup'),
                    meta: { title: '基本设置', keepAlive: false }
                },
                //组织官网-导航设置
                {
                    path: 'navSetup',
                    component: () =>
                        import ('../pages/website/navSetup'),
                    meta: { title: '导航设置', keepAlive: false }
                },
                //组织官网-模板设置
                {
                    path: 'templateLibrary',
                    component: () =>
                        import ('../pages/website/templateLibrary'),
                    meta: { title: '模板设置', keepAlive: false }
                },
                //组织官网-模板设置
                {
                    path: 'newAssTemplateSeting',
                    component: () =>
                        import ('../pages/website/newAssTemplateSeting'),
                    meta: { title: '模板设置', keepAlive: false }
                },
                //组织官网-楼层管理
                {
                    path: 'floorManagement',
                    component: () =>
                        import ('../pages/website/floorManagement'),
                    meta: { title: '楼层管理', keepAlive: false }
                },
                //组织官网-添加楼层
                {
                    path: 'addfloor',
                    component: () =>
                        import ('../pages/website/addfloor'),
                    meta: { title: '添加楼层', keepAlive: false }
                },
                //会员管理-会员列表
                {
                    path: 'membersList',
                    component: () =>
                        import ('../pages/membersGL/membersList'),
                    meta: { title: '会员列表', keepAlive: false }
                },
                {
                    path: 'membersGrouping',
                    component: () =>
                        import ('../pages/membersGL/membersGrouping'),
                    meta: { title: '会员分组', keepAlive: false }
                },
                {
                    path: 'toMemberGroupList',
                    component: () =>
                        import ('../pages/membersGL/toMemberGroupList'),
                    meta: { title: '会员分组', keepAlive: false }
                },
                {
                    path: 'basicInformation',
                    component: () =>
                        import ('../pages/membersGL/basicInformation'),
                    meta: { title: '基本信息', keepAlive: false }
                },
                {
                    path: 'CVList',
                    component: () =>
                        import ('../pages/membersGL/CVList'),
                    meta: { title: '个人履历', keepAlive: false }
                },
                {
                    path: 'companyCardPage',
                    component: () =>
                        import ('../pages/membersGL/companyCardPage'),
                    meta: { title: '企业主页', keepAlive: false }
                },
                //账号-体验记录
                {
                    path: 'recordTy',
                    component: () =>
                        import ('../pages/membersGL/recordTy'),
                    meta: { title: '体验记录', keepAlive: false }
                },
                //会员管理-会长风采
                // {
                //     path: 'membersFc',
                //     component: () => import('../pages/membersGL/membersFc'),
                //     meta: {title: '会长风采', keepAlive: false}
                // },
                // //会员管理-会费管理
                // {
                //     path: 'membersGLMoney',
                //     component: () => import('../pages/membersGL/membersGLMoney'),
                //     meta: {title: '会费管理', keepAlive: false}
                // },
                //会员管理-入会审核
                {
                    path: 'membership',
                    component: () =>
                        import ('../pages/membersGL/membership'),
                    meta: { title: '入会审核', keepAlive: false }
                },
                // //会员管理-缴费记录
                // {
                //     path: 'paymentRecords',
                //     component: () => import('../pages/membersGL/ship/paymentRecords'),
                //     meta: {title: '缴费记录', keepAlive: false}
                // },
                //会员管理-粉丝管理
                {
                    path: 'fansManagement',
                    component: () =>
                        import ('../pages/membersGL/fansManagement'),
                    meta: { title: '粉丝管理', keepAlive: false }
                },
                //会员管理-会员回收站
                {
                    path: 'membersDel',
                    component: () =>
                        import ('../pages/membersGL/membersDel'),
                    meta: { title: '会员回收站', keepAlive: false }
                },
                //内容管理-首页轮播
                {
                    path: 'homePage',
                    component: () =>
                        import ('../pages/managementMobile/homePage'),
                    meta: { title: '首页轮播', keepAlive: false }
                },
                //内容管理-公告列表
                {
                    path: 'announcementList',
                    component: () =>
                        import ('../pages/management/announcementList'),
                    meta: { title: '公告列表', keepAlive: false }
                },
                //内容管理-首页菜单
                {
                    path: 'homeMenu',
                    component: () =>
                        import ('../pages/managementMobile/homeMenu'),
                    meta: { title: '首页菜单', keepAlive: false }
                },
                {
                    path: 'projectListTy',
                    component: () =>
                        import ('../pages/managementMobile/projectList'),
                    meta: { title: '专题列表', keepAlive: false }
                },
                //内容管理-组织工作
                {
                    path: 'membersWork',
                    component: () =>
                        import ('../pages/management/membersWork'),
                    meta: { title: '组织工作', keepAlive: false }
                },
                //内容管理-栏目管理
                {
                    path: 'contentList',
                    component: () =>
                        import ('../pages/management/contentList'),
                    meta: { title: '文章管理', keepAlive: false }
                },
                //内容管理-捐赠管理
                {
                    path: 'donationManagement',
                    component: () =>
                        import ('../pages/management/donationManagement'),
                    meta: { title: '捐赠管理', keepAlive: false }
                },
                //内容管理-自定义页面
                {
                    path: 'customPage',
                    component: () =>
                        import ('../pages/management/customPage'),
                    meta: { title: '自定义页面', keepAlive: false }
                },
                //内容管理-新增自定义页面
                {
                    path: 'addCustomPage',
                    component: () =>
                        import ('../pages/management/addCustomPage'),
                    meta: { title: '页面设置', keepAlive: false }
                },
                //内容管理-新增自定义页面
                {
                    path: 'stepCustomPage',
                    component: () =>
                        import ('../pages/management/stepCustomPage'),
                    meta: { title: '页面设置', keepAlive: false }
                },
                //内容管理-栏目管理
                {
                    path: 'lanmuList',
                    component: () =>
                        import ('../pages/managementMobile/lanmuList'),
                    meta: { title: '页面设置', keepAlive: false }
                },
                //广场管理-轮播图
                {
                    path: 'shufflingList',
                    component: () =>
                        import ('../pages/square/shufflingList'),
                    meta: { title: '轮播图', keepAlive: false }
                },
                //广场管理-动态列表
                {
                    path: 'dynamicList',
                    component: () =>
                        import ('../pages/square/dynamicList'),
                    meta: { title: '动态列表', keepAlive: false }
                },
                //广场管理-圈子详情
                {
                    path: 'circleDetails',
                    component: () =>
                        import ('../pages/square/circleDetails'),
                    meta: { title: '日常圈子详情', keepAlive: false }
                },
                //广场管理-招商引资详情
                {
                    path: 'merchantDetails',
                    component: () =>
                        import ('../pages/square/merchantDetails'),
                    meta: { title: '招商引资详情', keepAlive: false }
                },
                //广场管理-人脉对接详情
                {
                    path: 'handleDetails',
                    component: () =>
                        import ('../pages/square/handleDetails'),
                    meta: { title: '人脉对接详情', keepAlive: false }
                },
                //广场管理-动态详情
                {
                    path: 'dynamicDetails',
                    component: () =>
                        import ('../pages/square/dynamicDetails'),
                    meta: { title: '动态详情', keepAlive: false }
                },
                //意见反馈
                {
                    path: 'userFeedback',
                    component: () =>
                        import ('../pages/userFeedback/userFeedback'),
                    meta: { title: '意见反馈', keepAlive: false }
                },
                //系统设置---基础设置
                {
                    path: 'systemSet',
                    component: () =>
                        import ('../pages/systemSet/systemSet'),
                    meta: { title: '系统设置', keepAlive: false }
                },
                // //系统设置---角色管理
                // {
                //     path: 'roleManagement',
                //     component: () => import('../pages/systemSet/roleManagement'),
                //     meta: {title: '角色管理', keepAlive: false}
                // },
                //系统设置---账号管理
                {
                    path: 'accountManagement',
                    component: () =>
                        import ('../pages/systemSet/accountManagement'),
                    meta: { title: '账号管理', keepAlive: false }
                },
                //营销管理-红包管理
                {
                    path: 'redEnvelopeManagement',
                    component: () =>
                        import ('../pages/marketingManagement/redEnvelopeManagement'),
                    meta: { title: '红包管理', keepAlive: false }
                },
                //营销管理-积分管理
                {
                    path: 'integralManagement',
                    component: () =>
                        import ('../pages/marketingManagement/integralManagement'),
                    meta: { title: '积分管理', keepAlive: false }
                },
                //营销管理-资料库
                {
                    path: 'database',
                    component: () =>
                        import ('../pages/marketingManagement/database'),
                    meta: { title: '资料库', keepAlive: false }
                },
                //资料库
                {
                    path: 'dataList',
                    component: () =>
                        import ('../pages/data/dataList'),
                    meta: { title: '资料库', keepAlive: false }
                },
                //资料详情
                {
                    path: 'dataDetails',
                    component: () =>
                        import ('../pages/data/dataDetails'),
                    meta: { title: '资料详情', keepAlive: false }
                },
                //操作日志
                {
                    path: 'operationLog',
                    component: () =>
                        import ('../pages/operationLog/main'),
                    meta: { title: '资料详情', keepAlive: false }
                },
                //体验账号
                // {
                //     path: 'experience_account',
                //     component: () => import('../pages/account/experience_account'),
                //     meta: {title: '资料详情', keepAlive: false}
                // },
                //客服管理
                {
                    path: 'serviceCoutomerManagement',
                    component: () =>
                        import ('../pages/customerService/serviceCoutomerManagement'),
                    meta: { title: '客服管理', keepAlive: false }
                },
                // 投促会 -- 专题栏  ---  专题列表
                {
                    path: 'projectList',
                    component: () =>
                        import ('../pages/specialBar/projectList'),
                    meta: { title: '专题列表', keepAlive: false }
                },
                // 投促会 -- 联席会办公室  ---  本地商协会
                {
                    path: 'localOffice',
                    component: () =>
                        import ('../pages/association/localOffice'),
                    meta: { title: '本地商协会', keepAlive: false }
                },
                // 投促会 -- 联席会办公室  ---  异地商协会
                {
                    path: 'anotherOffice',
                    component: () =>
                        import ('../pages/association/anotherOffice'),
                    meta: { title: '异地商协会', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  轮播图设置
                {
                    path: 'swiperStep',
                    component: () =>
                        import ('../pages/chinaMerchants/swiperStep'),
                    meta: { title: '轮播图设置', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  轮播图设置
                {
                    path: 'swiperStep',
                    component: () =>
                        import ('../pages/chinaMerchants/swiperStep'),
                    meta: { title: '轮播图设置', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  开发区推介
                {
                    path: 'developmentZone',
                    component: () =>
                        import ('../pages/chinaMerchants/developmentZone'),
                    meta: { title: '开发区推介', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  添加开发区
                {
                    path: 'addFKQ',
                    component: () =>
                        import ('../pages/chinaMerchants/addFKQ'),
                    meta: { title: '添加开发区', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  项目推介
                {
                    path: 'projectIntroduction',
                    component: () =>
                        import ('../pages/chinaMerchants/projectIntroduction'),
                    meta: { title: '项目推介', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  产品推介
                {
                    path: 'productIntroduction',
                    component: () =>
                        import ('../pages/chinaMerchants/productIntroduction'),
                    meta: { title: '产品推介', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  企业推介
                {
                    path: 'enterpriseIntroduction',
                    component: () =>
                        import ('../pages/chinaMerchants/enterpriseIntroduction'),
                    meta: { title: '企业推介', keepAlive: false }
                },
                // 投促会 -- 招商引资  ---  中行跨境撮合平台
                {
                    path: 'crossBorder',
                    component: () =>
                        import ('../pages/chinaMerchants/crossBorder'),
                    meta: { title: '中行跨境撮合平台', keepAlive: false }
                },
                // 投促会 -- 云上展厅  ---  轮播图设置
                {
                    path: 'swiperStepYS',
                    component: () =>
                        import ('../pages/cloudHall/swiperStep'),
                    meta: { title: '轮播图设置', keepAlive: false }
                },
                // 投促会 -- 云上展厅  ---  开发区展示
                {
                    path: 'developmentZoneYS',
                    component: () =>
                        import ('../pages/cloudHall/developmentZone'),
                    meta: { title: '开发区展示', keepAlive: false }
                },
                // 投促会 -- 云上展厅  ---  优质企业展示
                {
                    path: 'highQualityCompany',
                    component: () =>
                        import ('../pages/cloudHall/highQualityCompany'),
                    meta: { title: '优质企业展示', keepAlive: false }
                },
                // 投促会 -- 云上展厅  ---  产品展示
                {
                    path: 'productDisplay',
                    component: () =>
                        import ('../pages/cloudHall/productDisplay'),
                    meta: { title: '产品展示', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  轮播图设置
                {
                    path: 'swiperStepKFQ',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/swiperStepKFQ'),
                    meta: { title: '轮播图设置', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  聚焦双碳
                {
                    path: 'doubleCarbon',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/doubleCarbon'),
                    meta: { title: '聚焦双碳', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  白皮书
                {
                    path: 'whitePaper',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/whitePaper'),
                    meta: { title: '白皮书', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  动态数据
                {
                    path: 'dynamicData',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/dynamicData'),
                    meta: { title: '动态数据', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  开发区规划图
                {
                    path: 'developmentMap',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/developmentMap'),
                    meta: { title: '开发区规划图', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  综合服务
                {
                    path: 'comprehensiveService',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/comprehensiveService'),
                    meta: { title: '综合服务', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  新增
                {
                    path: 'addFWFKQ',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/addFWFKQ'),
                    meta: { title: '新增开发区', keepAlive: false }
                },
                // 投促会 -- 服务开发区  ---  开发区
                {
                    path: 'allFWFKQList',
                    component: () =>
                        import ('../pages/ServiceDevelopmentZone/allFWFKQList'),
                    meta: { title: '开发区', keepAlive: false }
                },

                //云中购高级表单
                {
                    path: 'advancedFormSH',
                    component: () =>
                        import ('../pages/tissueForm/advancedForm'),
                    meta: { title: '高级表单', keepAlive: false }
                },
                {
                    path: 'formDetallSH',
                    component: () =>
                        import ('../pages/tissueForm/formDetall'),
                    meta: { title: '表单详情', keepAlive: false }
                },
                {
                    path: 'publishAdvancedFormSH',
                    component: () =>
                        import ('../pages/tissueForm/publishAdvancedForm'),
                    meta: { title: '新增表单', keepAlive: false }
                },
            ]
        },
        {
            path: '/login',
            component: () =>
                import ('../pages/login'),
            meta: { title: '登录' }
        },
        {
            path: "*",
            redirect: "/dashboard"
        }
    ]
})
