export default {
    //处理富文本标签
    getSimpleText(html) {
        var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = html.replace(re1, ''); //执行替换成空字符
        var str1 = msg.replace(/&nbsp;/gi, "")
        return str1;
    },
    renderTime(date) {
        var dateee = new Date(date).toJSON();
        return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //模板类型
    templateStyle(type){
        let str = null
        if (type == 0) {
            str = '轮播图'
        }else if (type == 1) {
            str = '视频样式一'
        }else if (type == 2) {
            str = '视频样式二'
        }else if (type == 3) {
            str = '视频样式三'
        }else if (type == 4) {
            str = '视频样式四'
        }else if (type == 5) {
            str = '视频样式五'
        }else if (type == 6) {
            str = '视频样式六'
        }else if (type == 7) {
            str = '视频样式七'
        }else if (type == 8) {
            str = '文章样式一'
        }else if (type == 9) {
            str = '文章样式二'
        }else if (type == 10) {
            str = '文章样式三'
        }else if (type == 11) {
            str = '文章样式四'
        }else if (type == 12) {
            str = '文章样式五'
        }else if (type == 13) {
            str = '文章样式六'
        }else if (type == 14) {
            str = '文章样式七'
        }else if (type == 15) {
            str = '文章样式八'
        }else if (type == 16) {
            str = '文章样式九'
        }else if (type == 17) {
            str = '文章样式十'
        }else if (type == 18) {
            str = '专题样式一'
        }else if (type == 19) {
            str = '专题样式二'
        }else if (type == 20) {
            str = '专题样式三'
        }else if (type == 21) {
            str = '商品'
        }else if (type == 22) {
            str = '广告图'
        }else if (type == 23) {
            str = '展示图'
        }
        return str
    }
}
